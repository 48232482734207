import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import { Seo } from "../../components/seo";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function AccPayService() {
  /* Slider */
  // const AccPaySlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Accounts Payable Service In Hosur`,
  //     content: `Online Accounts Payable Service with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/onecompslider.png",
  //     alt_tag: "Best Online Accounts Payable Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Account Payable Service`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const AccPayAboutData = {
    header: `Accounts Payable Service`,
    sub_title: `Online Accounts Payable Service with TODAYFILINGS Experts.`,
    content_paragraph: [
      // `Outsourcing services for accounts payable aid in
      //   streamlining numerous tasks like checking, coordinating,
      //   endorsing, and confirming. It is a difficult task for the
      //   business to handle numerous solicitations and various
      //   accounts payable paperwork at once. The accounts payable
      //   department also invests a lot of time and effort in this
      //   process.`,

      `Accounts payable service is useful in sparing oneself from
        unnecessary tasks. It frees up workers to focus on other
        pressing business activities, increases preparation times,
        and speeds up payments. The accounts payable services make
        it possible to complete various tasks without heavily
        leaning on the accounts payable department.`,
    ],
  };

  /* Scroll Nav Data */
  const AccPAyScrollId = [
    {
      id: `#invoicing`,
      heading: `Invoice`,
    },
    {
      id: `#cost-management`,
      heading: `Cost-Management`,
    },
    {
      id: `#role`,
      heading: `Role`,
    },
  ];

  /* Img Content Component Data */
  const AccPayIcData = {
    id: 'invoicing',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Accounts Payable Services Invoicing',
    image: '/imgs/registration/iec/iec-reg.png',
    alt_tag: 'Accounts Payable Services Invoicing',
    points: [
      {
        head: `The Importance Of Correct Invoice Processing`,
        content: `An adequately processed receipt benefits from early instalment payment 
          restrictions and issue-free payable clearance. They pay on time, which establishes 
          predictability in the organization's cash flow evolution.`,
        icon: true,
      },
      {
        head: `Accounts Payable's Service Invoice Indexing`,
        content: `Developing a work procedure and being proactive with
          your solicitations can assist the organization manage
          the flow of vendor invoices`,
        icon: true,
      },
      {
        head: `Accounts Payable Outsourcing Services`,
        content: `Going forward with highly skilled and
          seasoned experts will give you the benefit of
          outsourcing your accounts payable.`,
        icon: true,
      },
      {
        head: `Payment Processing In Accounts Payable`,
        content: ` The credit period must be tightly controlled by the
          business because it directly affects its revenue.`,
        icon: true,
      },
      {
        content: ` The government of the foreign countries confirms the dealer on the basis of the IEC code application process.`,
        icon: true,
      },
    ],
  };
  /* Content Form Component Data */
  const AccPayCfData = {
    id: 'cost-management',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Accounts Payable Services Cost Management',
    content: '',
    body: [
      {
        head: `Cost-Reduction Strategy`,
        points: `Internal accounts payable departments may end up being
        too expensive for businesses. Reassigning accounts
        payable duties to a team of professionals can help the
        organization save money and time.`,
        icon: true,
      },
      {
        head: `Increased Productivity`,
        points: `Owners of contemporary companies are making their
        accounts available for re-appropriation. With the
        collaboration of seasoned records professionals for
        the management of their accounting services, they feel
        more comfortable.`,
        icon: true,
      },
      {
        head: `Reduce Errors`,
        points: `Modern business owners are presenting their accounts
        for reappropriation in order to rule out errors,
        notably spreadsheet errors. With the collaboration of
        seasoned records professionals for the management of
        their accounting services, they feel more comfortable.`,
        icon: true,
      },
      {
        head: `GRIR reconciliation is necessary`,
        points: `To ensure that your books are excellent and accurately
        reflect the state of your records, accounts payable
        should be handled on a regular basis.`,
        icon: true,
      },
      {
        head: `Vendor Statement Reconciliation`,
        points: `Don't let the fact that
        your statements aren't timely accommodated lead you to
        struggle with the question.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const AccPayCiData = {
    id: 'role',
    background: '',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'TODAYFILINGS Role In Account Payable Service',
    paragraph: ``,
    points: [
      {
        head: `Role of Invoice Indexing`,
        content: `Your PO and Non-PO invoices and expenses will be
        organized into a file, after which we will contact the
        required endorsing authority. complete MIS for the real-time
        permeability of invoice status.`,
        icon: true,
      },
      {
        head: `The value of the vendor help desk`,
        content: `All questions coming from your seller are handled by a
        dedicated AP Help desk team. This is necessary so that
        your sellers can be clearly heard and the hierarchical
        relationship is strengthened.`,
        icon: true,
      },
      {
        head: `Role in GRIR Reconciliation`,
        content: ` We respond to merchant requests at the unit level of
        purchase orders, approving them, and we report or
        clear any variations over time.`,
        icon: true,
      },
      {
        head: `Role in Payment Processing`,
        content: `Every payment is within your control. Our team creates
        an instalment proposal in the accounting ERP system,
        includes the fundamental solicitations, enlists the
        leading merchants, and prepares a maturing of
        payable's.`,
        icon: true,
      },
      {
        head: `Role in Vendor Data Management`,
        content: `Your vendors provide us with the essential information
        and tax certificates. When a vendor needs their
        information updated, we also take care of their
        expectations. Changes to the business's name, contact
        information, tax status, and nature may all require
        updating.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/iec/iec-benefits.png',
    alt_tag: 'TODAYFILINGS Role in Account Payable Service',
  };
  /* FAQ data */
  var FAQ_data = [
    {
      header: 'What is the whole cycle of accounts payable?',
      body: [
        {
          content: `It includes the entire range of accounting tasks required to
          finish a transaction after the order has been placed and the good
          or service has been received. Accounts payable involves matching
          paperwork, approving invoices, writing checks, and documenting
          payments as part of its whole cycle.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: "What does GL code mean for payable's?",
      body: [
        {
          content: `Each financial entry in a company's ledger is given a General
          Ledger Code (GL Code), which is a group of alphanumeric
          characters.`,
          icon: false,
        },
      ],
    },

    // 3
    {
      header: 'What does it mean when the amount of accounts due rises?',
      body: [
        {
          content: `An essential number on a company's balance sheet is accounts
          payable (AP). If AP rises over the previous period, it indicates
          that the business is using credit rather than cash to purchase
          more goods and services.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Accounts Payable Service'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Online E-filing'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={AccPaySlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={AccPayAboutData} />

          <Counter />
          <ScrollNav scroll_data={AccPAyScrollId} />

          <ImgContent item={AccPayIcData} />

          <ContentForm CFSection_data={AccPayCfData} />

          <ContentImg CISection_data={AccPayCiData} />
          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
